import { Heading as BaseHeading, HeadingProps } from 'baseui/heading'
import { BlockOverrides } from 'baseui/block'
import { withOverrides } from './overrides'
import { ourColors } from './Colors'

const globalOverrides: BlockOverrides = {
  Block: {
    style: () => ({
      marginTop: '20px',
      marginBottom: '20px',
      color: ourColors.utechOrange,
      letterSpacing: '-0.5px'
    })
  }
}

const OverriddenHeading = withOverrides<HeadingProps>(BaseHeading, globalOverrides)

export const Heading = withOverrides<HeadingProps>(OverriddenHeading, globalOverrides)
